<template>
  <el-scrollbar height="calc(100vh - 3.5rem)">
    <div :style="mainBodyStyle" class="y-main-body">
      <slot></slot>
    </div>
    <y_layout_foot></y_layout_foot>
  </el-scrollbar>
</template>

<script>
import y_layout_foot from "@/components/common/y_layout_foot";

export default {
  name: "y_layout_main",
  components: {y_layout_foot},
  props: {
    mainBodyStyle: {
      type: String,
      default: ""
    }
  },
  data() {
    return {}
  }
}
</script>

<style scoped>
.y-main-body {
  min-height: calc(100vh - 9rem);
  padding: 1.25rem
}
</style>