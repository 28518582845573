<template>
  <el-container>
    <el-aside class="flex-def flex-zTopBottom" width="7.5rem" style="padding-left: .5rem;background-color: #1C2C3C">
      <div class="flex-def flex-zCenter flex-cCenter"
           style="margin-bottom: .5rem;border-bottom: 1px solid #000000;height: 3.5rem;width: 100%">
        <div @click="$router.push({name:home_page,params:$route.params})" class="y-pointer">
          <img v-if="$store.state.setting.site_logo" :src="$store.state.setting.site_logo | tomedia" style="height: 2rem;width: 2rem;" alt="logo">
          <span v-else class="y-desc">logo</span>
        </div>
      </div>
      <el-menu
          :router="true"
          :uniqueOpened="true"
          :default-active="path"
          class="el-menu-vertical"
          background-color="#1C2C3C"
          text-color="#ffffff"
          active-text-color="#ffffff">
        <el-menu-item v-for="(item,key) in menus" :key="key"
                      :index="item.path"
                      :route="{name:item.name,params:$route.params}"
        >
          <i style="font-weight: 600" :class="item.icon"></i>
          <template #title>
            <span style="font-weight: 600">{{ item.title }}</span>
          </template>
        </el-menu-item>
      </el-menu>
    </el-aside>
    <el-container>
      <el-header height="3.5rem" class="header-box">
        <y_layout_head></y_layout_head>
      </el-header>
      <el-main class="main-box">
        <y_layout_main :main-body-style="mainBodyStyle"><slot></slot></y_layout_main>
      </el-main>
    </el-container>
  </el-container>
</template>

<script>
// import menus from "./y-layout-menu";
import y_layout_head from "@/components/common/y_layout_head";
import y_layout_main from "@/components/common/y_layout_main";
import {mapActions} from "vuex";
export default {
  name: "y_layout",
  props:{
    mainBodyStyle:{
      type:String,
      default:""
    }
  },
  components: {
    y_layout_head,
    y_layout_main,
  },
  data() {
    return {
      menus: [],
      path: "",
      home_page:""
    }
  },
  created() {
    this.onRouteChanged()
  },
  mounted() {
    this.$store.dispatch("addons/load");
    this.load().then(res=>{
      this.menus = res.list;
      this.home_page = res.home_page;
    });
  },
  methods: {
    onRouteChanged() {
      let that = this;
      that.path = this.$route.matched[0].path.replace("/:uni_acid","");
    },
    ...mapActions("menu",["load"])
  },
  watch: {
    '$route': 'onRouteChanged'
  }
}
</script>
<style>
li.el-menu-item {
  padding: 0 !important;
  padding-left: 1rem !important;
}

li.el-menu-item.is-active {
  background-color: #5D60F6 !important;
  border-radius: .5rem 0 0 .5rem;
}

.el-menu {
  border-right: unset !important;
}
</style>
<style scoped>
@import "y-layout-base.css";
.el-menu-vertical {
  width: 100%;
}
</style>